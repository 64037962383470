import { isProd } from "./index";

export function getBackendGraphqlUrl() {
  return isProd()
    ? "https://cursive-backend.herokuapp.com/graphql"
    : "http://localhost:4000/graphql";
}

export function getBackendUrl() {
  return isProd()
    ? "https://cursive-backend.herokuapp.com"
    : "http://localhost:4000";
}

export function getBackendSubscriptionsUrl() {
  return isProd()
    ? "wss://cursive-backend.herokuapp.com/graphql"
    : "ws://localhost:4000/graphql";
}

export function getFrontendUrl() {
  return isProd() ? "https://cursive.org" : "http://localhost:3000";
}
