import React from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Card,
} from "react-bootstrap";

function ProfilePage() {
  return (
    <div>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="#home">Studio.tv</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#link">Link</Nav.Link>
            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Form inline>
            <FormControl type="text" placeholder="Search" className="mr-sm-2" />
            <Button variant="outline-success">Search</Button>
          </Form>
        </Navbar.Collapse>
      </Navbar>
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "32px 0px",
            alignItems: "center",
          }}
        >
          <div style={{ maxWidth: 640, width: "100%" }}>
            <Card>
              <Card.Body>
                <div
                  style={{ display: "flex", alignItems: "center", padding: 12 }}
                >
                  <img
                    style={{ borderRadius: "50%", height: 132, width: 132 }}
                    src="https://stream-club-s3.s3.amazonaws.com/f6eaeccf-8767-4e44-9aa0-de3aac2a3f0c.jfif"
                    alt=""
                  />
                  <div style={{ marginLeft: 24 }}>
                    <h1>Shannon Watts</h1>
                    <h2>tagline</h2>
                    <div style={{ wordBreak: "break-word" }}>
                      fjskladjkfjalksdjflaskdljfsjdfkjaldjfklkjlfdklfjklafkjlsdjfksdkjljfklasdjklfaljksdfjkljklfd
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <div style={{ marginTop: 20 }}>
              <div>Live now</div>
              <div
                style={{ display: "flex", alignItems: "center", marginTop: 20 }}
              >
                <img
                  style={{ height: 132, width: 132 }}
                  src="https://stream-club-s3.s3.amazonaws.com/f6eaeccf-8767-4e44-9aa0-de3aac2a3f0c.jfif"
                  alt=""
                />
                <div style={{ marginLeft: 24 }}>
                  <h1>yoga for beginners</h1>
                  <h3>70 participants</h3>
                  <div>join me for succ</div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <div>Upcoming</div>
              <div
                style={{ display: "flex", alignItems: "center", marginTop: 20 }}
              >
                <img
                  style={{ height: 132, width: 132 }}
                  src="https://stream-club-s3.s3.amazonaws.com/f6eaeccf-8767-4e44-9aa0-de3aac2a3f0c.jfif"
                  alt=""
                />
                <div style={{ marginLeft: 24 }}>
                  <h1>yoga for beginners</h1>
                  <h3>70 participants</h3>
                  <div>join me for succ</div>
                </div>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", marginTop: 20 }}
              >
                <img
                  style={{ height: 132, width: 132 }}
                  src="https://stream-club-s3.s3.amazonaws.com/f6eaeccf-8767-4e44-9aa0-de3aac2a3f0c.jfif"
                  alt=""
                />
                <div style={{ marginLeft: 24 }}>
                  <h1>yoga for beginners</h1>
                  <h3>70 participants</h3>
                  <div>join me for succ</div>
                </div>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", marginTop: 20 }}
              >
                <img
                  style={{ height: 132, width: 132 }}
                  src="https://stream-club-s3.s3.amazonaws.com/f6eaeccf-8767-4e44-9aa0-de3aac2a3f0c.jfif"
                  alt=""
                />
                <div style={{ marginLeft: 24 }}>
                  <h1>yoga for beginners</h1>
                  <h3>70 participants</h3>
                  <div>join me for succ</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 12,
          }}
        >
          <img
            style={{ borderRadius: "50%", height: 132, width: 132 }}
            src="https://stream-club-s3.s3.amazonaws.com/f6eaeccf-8767-4e44-9aa0-de3aac2a3f0c.jfif"
            alt=""
          />
          <div>Profile page</div>
          <div>Description of the coach</div>
          <div>TODO list of classes here</div>
        </div> */}
      </Container>
    </div>
  );
}

export default ProfilePage;
