import LogRocket from "logrocket";
import React from "react";
import ReactDOM from "react-dom";
import "simplebar/dist/simplebar.min.css";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { isProd } from "./utils/index";
if (isProd()) {
  LogRocket.init("pgckuz/prowess-web-prod");
} else {
  LogRocket.init("pgckuz/prowess-web-dev");
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
