export const DARK_PRIMARY_DARK = "rgb(42, 46, 48)";
export const DARK_PRIMARY = "rgb(48, 52, 55)";
export const DARK_PRIMARY_LIGHT = "rgb(67, 67, 70)";
export const DARK_SECONDARY_DARK = "rgb(131, 133, 135)";
export const DARK_SECONDARY = "rgb(172, 174, 175)";
export const DARK_SECONDARY_LIGHT = "rgb(234, 234, 235)";
export const DARK_TERTIARY_DARK = "rgb(149, 126, 197)";
export const DARK_TERTIARY = "rgb(191, 169, 237)";
export const DARK_TERTIARY_LIGHT = "rgb(228, 215, 255)";
// DEPRECATED
export const PRIMARY_DARK = "rgb(42, 46, 48)";
export const PRIMARY = "rgb(48, 52, 55)";
export const PRIMARY_LIGHT = "rgb(67, 67, 70)";
export const SECONDARY_DARK = "rgb(131, 133, 135)";
export const SECONDARY = "rgb(172, 174, 175)";
export const SECONDARY_LIGHT = "rgb(234, 234, 235)";
export const TERTIARY_DARK = "rgb(149, 126, 197)";
export const TERTIARY = "rgb(191, 169, 237)";
export const TERTIARY_LIGHT = "rgb(228, 215, 255)";

export const DARK_WARNING = "rgb(255, 99, 99)";

export const DARK_PRIMARY_ONE = "rgb(42, 46, 48)";

export const LIGHT_PRIMARY_ONE = "rgb(244, 243, 252)";
export const LIGHT_PRIMARY_TWO = "rgb(252, 252, 255)";
export const LIGHT_PRIMARY_THREE = "rgb(255, 255, 255)";
export const LIGHT_SECONDARY_ONE = "rgb(51, 51, 51)";
export const LIGHT_SECONDARY_TWO = "rgb(153, 153, 153)";
export const LIGHT_SECONDARY_THREE = "rgb(191, 191, 191)";
export const LIGHT_SECONDARY_FOUR = "rgb(219, 219, 219)";
export const LIGHT_SECONDARY_FIVE = "rgb(242, 242 ,242)";
export const LIGHT_TERTIARY_ONE = "rgb(82, 106, 206)";
export const LIGHT_TERTIARY_ONE_POINT_FIVE = "rgb(102, 126, 226)";
export const LIGHT_TERTIARY_TWO = "rgb(173, 191, 248)";
export const LIGHT_TERTIARY_THREE = "rgb(220, 227, 249)";

export const LIGHT_POSITIVE_ONE = "rgb(10, 57, 30)";
export const LIGHT_POSITIVE_TWO = "rgb(39, 171, 94)";
export const LIGHT_POSITIVE_THREE = "rgb(157, 216, 182)";

export const LIGHT_NEGATIVE_ONE = "rgb(255, 99, 99)";

export enum Theme {
  "LIGHT" = "LIGHT",
  "DARK" = "DARK",
}
