import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  LIGHT_PRIMARY_THREE,
  LIGHT_SECONDARY_FIVE,
  LIGHT_SECONDARY_FOUR,
  LIGHT_SECONDARY_TWO,
  LIGHT_TERTIARY_ONE,
  LIGHT_TERTIARY_ONE_POINT_FIVE,
} from "../colors";
import Button from "../components/shared/Button";

type Props = {} & RouteComponentProps;

function LandingPage(props: Props) {
  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const utmSource = query.get("utm_source");

    // TODO: Check FB referral and add a specific landing page view from FB
    if (utmSource) {
      analytics.track("Landing page from FB", { campaignId: utmSource });
    }

    analytics.track("Landing page view");
  }, []);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 640px)" });

  const [emailAddress, setEmailAddress] = useState<string>("");

  const submitEmail = () => {};

  const redirectToLogin = () => {
    props.history.push("/login");
  };

  const redirectToLanding = () => {
    props.history.push("/");
  };

  const redirectToHiddenTeacherProfile = () => {
    props.history.push("/teacher");
  };

  const redirectToGetStarted = () => {};

  return (
    <MyContainer>
      <Header>
        <Wrapper>
          <HeaderContent>
            <LogoTitle onClick={redirectToLanding}>Studio</LogoTitle>
            <AboutButton onClick={redirectToLogin}>Login</AboutButton>
            <Button onClick={redirectToGetStarted}>Get Started</Button>
          </HeaderContent>
        </Wrapper>
      </Header>
      <HeroMessage>
        <HeaderText>Say hello to your audience</HeaderText>
        <HeaderSubtext>
          Run and manage a paid livestream with one click.
        </HeaderSubtext>
        <JoinButton
          onClick={() => {
            props.history.push("/teachersignup");
            analytics.track("Signup started");
          }}
        >
          Start a stream
        </JoinButton>
      </HeroMessage>
      <Point style={{ marginTop: 200 }}>
        <PointTextContents>
          <PointTitle>Own Your Audience</PointTitle>
          <PointSubtext>
            Own your follower’s emails and use our analytics and marketing tools
            to track audience engagement and engage directly with your fans.
          </PointSubtext>
        </PointTextContents>
        <Picture left />
      </Point>
      <Point style={{ marginTop: 120 }}>
        <Picture />
        <PointTextContents left>
          <PointTitle left>Own Your Audience</PointTitle>
          <PointSubtext left>
            Own your follower’s emails and use our analytics and marketing tools
            to track audience engagement and engage directly with your fans.
          </PointSubtext>
        </PointTextContents>
      </Point>
      <Break style={{ marginTop: 120, marginBottom: 120 }} />
      <PointTitle style={{ marginBottom: -16, alignSelf: "flex-start" }}>
        Other studios
      </PointTitle>
      <PointTitle style={{ alignSelf: "flex-start" }}>in action</PointTitle>
      <StudioCardScroll>
        <StudioCard first>
          <StudioThumbnail />
          <Row style={{ marginTop: 16 }}>
            <ProfilePicture />
            <Column style={{ marginLeft: 20 }}>
              <ProfileName>Keanu Taylor</ProfileName>
              <FollowerCount>35.6k followers</FollowerCount>
            </Column>
          </Row>
        </StudioCard>
        <StudioCard>
          <StudioThumbnail />
          <Row style={{ marginTop: 16 }}>
            <ProfilePicture />
            <Column style={{ marginLeft: 20 }}>
              <ProfileName>Keanu Taylor</ProfileName>
              <FollowerCount>35.6k followers</FollowerCount>
            </Column>
          </Row>
        </StudioCard>
        <StudioCard>
          <StudioThumbnail />
          <Row style={{ marginTop: 16 }}>
            <ProfilePicture />
            <Column style={{ marginLeft: 20 }}>
              <ProfileName>Keanu Taylor</ProfileName>
              <FollowerCount>35.6k followers</FollowerCount>
            </Column>
          </Row>
        </StudioCard>
        <StudioCard>
          <StudioThumbnail />
          <Row style={{ marginTop: 16 }}>
            <ProfilePicture />
            <Column style={{ marginLeft: 20 }}>
              <ProfileName>Keanu Taylor</ProfileName>
              <FollowerCount>35.6k followers</FollowerCount>
            </Column>
          </Row>
        </StudioCard>
        <StudioCard>
          <StudioThumbnail />
          <Row style={{ marginTop: 16 }}>
            <ProfilePicture />
            <Column style={{ marginLeft: 20 }}>
              <ProfileName>Keanu Taylor</ProfileName>
              <FollowerCount>35.6k followers</FollowerCount>
            </Column>
          </Row>
        </StudioCard>
        <StudioCard>
          <StudioThumbnail />
          <Row style={{ marginTop: 16 }}>
            <ProfilePicture />
            <Column style={{ marginLeft: 20 }}>
              <ProfileName>Keanu Taylor</ProfileName>
              <FollowerCount>35.6k followers</FollowerCount>
            </Column>
          </Row>
        </StudioCard>
        <StudioCard>
          <StudioThumbnail />
          <Row style={{ marginTop: 16 }}>
            <ProfilePicture />
            <Column style={{ marginLeft: 20 }}>
              <ProfileName>Keanu Taylor</ProfileName>
              <FollowerCount>35.6k followers</FollowerCount>
            </Column>
          </Row>
        </StudioCard>
        <StudioCard>
          <StudioThumbnail />
          <Row style={{ marginTop: 16 }}>
            <ProfilePicture />
            <Column style={{ marginLeft: 20 }}>
              <ProfileName>Keanu Taylor</ProfileName>
              <FollowerCount>35.6k followers</FollowerCount>
            </Column>
          </Row>
        </StudioCard>
      </StudioCardScroll>
      <Break style={{ marginTop: 120, marginBottom: 200 }} />
      <HeaderText>Let's get it</HeaderText>
      <JoinButton
        style={{ marginBottom: 160 }}
        onClick={() => {
          props.history.push("/teachersignup");
          analytics.track("Signup started");
        }}
      >
        Start a stream
      </JoinButton>
      <FooterLinks>
        <Column style={{ width: 160 }}>
          <LinkHeader>Company</LinkHeader>
          <FooterLink>About</FooterLink>
          <FooterLink>Blog</FooterLink>
          <FooterLink>Careers</FooterLink>
          <FooterLink>Pricing</FooterLink>
        </Column>
        <Column style={{ width: 160 }}>
          <LinkHeader>Contact</LinkHeader>
          <FooterLink>Contact Us</FooterLink>
          <FooterLink>Partnerships</FooterLink>
          <FooterLink>FAQs</FooterLink>
        </Column>
        <Column style={{ width: 160 }}>
          <LinkHeader>Legal</LinkHeader>
          <FooterLink>Privacy Policy</FooterLink>
          <FooterLink>Terms of Services</FooterLink>
        </Column>
      </FooterLinks>
      <Break style={{ marginTop: 40 }}></Break>
      <Footer>
        <LogoTitle onClick={redirectToLanding}>Studio</LogoTitle>
      </Footer>
    </MyContainer>
  );
}

export default withRouter(LandingPage);

const FooterLink = styled.div`
  margin-top: 4px;
  color: ${LIGHT_SECONDARY_TWO};
`;

const LinkHeader = styled.div`
  font-size: 20px;
`;

const ProfileName = styled.div`
  font-size: 24px;
`;

const FollowerCount = styled.div`
  margin-top: auto;
  color: ${LIGHT_SECONDARY_TWO};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
`;

const Footer = styled.div`
  display: flex;
  height: 120px;
  align-items: center;
  justify-content: flex-start;
  width: 1172px;
`;

const FooterLinks = styled.div`
  display: flex;
  width: 1172px;
  justify-content: flex-start;
`;

const StudioCard = styled.div<{ first?: boolean }>`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.first ? "margin-left: calc(50vw - 586px);" : "margin-left: 60px;"}
`;

const StudioThumbnail = styled.div`
  display: flex;
  min-width: 320px;
  height: 200px;
  background-color: black;
  border-radius: 16px;
`;

const ProfilePicture = styled.div`
  height: 64px;
  width: 64px;
  border-radius: 32px;
  background-color: black;
`;

const StudioCardScroll = styled.div`
  display: flex;
  width: 100vw;
  overflow: scroll;
`;

const Break = styled.div`
  width: 100%;
  display: flex;
  height: 1px;
  background-color: ${LIGHT_SECONDARY_FOUR};
`;

const Picture = styled.div<{ left?: boolean }>`
  width: 45%;
  display: flex;
  background-color: ${LIGHT_TERTIARY_ONE};
  ${(props) => props.left && "margin-left: auto;"}
`;

const Point = styled.div`
  display: flex;
`;

const PointTitle = styled.div<{ left?: boolean }>`
  display: block;
  font-size: 48px;
  margin-bottom: 24px;
  ${(props) => props.left && "text-align: left;"}
`;

const PointSubtext = styled.div<{ left?: boolean }>`
  display: block;
  font-size: 24px;
  ${(props) => props.left && "text-align: left;"}
`;

const PointTextContents = styled.div<{ left?: boolean }>`
  width: 45%;
  ${(props) => props.left && "margin-left: auto;"}
`;

const CenterCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubPointMessage = styled.div`
  color: ${LIGHT_SECONDARY_TWO};
  font-size: 20px;
`;

const JoinButton = styled.div<{ secondary?: boolean }>`
  display: flex;
  border-radius: 8px;
  padding: 20px 40px;
  background-color: ${(props) =>
    props.secondary ? LIGHT_PRIMARY_THREE : LIGHT_TERTIARY_ONE};
  border: 1px solid ${LIGHT_TERTIARY_ONE};
  color: ${(props) =>
    props.secondary ? LIGHT_TERTIARY_ONE : LIGHT_SECONDARY_FIVE};
  cursor: pointer;
  font-size: 24px;
  :hover {
    background-color: ${LIGHT_TERTIARY_ONE_POINT_FIVE};
    color: ${LIGHT_PRIMARY_THREE};
  }
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
  transition: 0.16s ease-in-out;
`;

const HeroCTA = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

const HeaderText = styled.div`
  font-size: 72px;
  margin-bottom: 40px;
  line-height: 120%;
`;

const HeaderTextWrap = styled.div`
  font-size: 64px;
  font-weight: bold;
`;

const HeaderSubtext = styled.div`
  display: flex;
  font-size: 28px;
  color: rgb(60, 60, 60);
  margin-bottom: 60px;
`;

const BoldHeaderTextWrap = styled.div`
  display: inline-block;
  color: rgb(89, 130, 237);
`;

const BoldHeaderText = styled.div`
  display: flex;
  color: rgb(89, 130, 237);
`;

const HeroMessage = styled.div`
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
`;

const HeroPicture = styled.img`
  width: 100%;
  margin-left: auto;
`;

const Hero = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
`;

const EthosButton = styled.div`
  cursor: pointer;
  margin-left: auto;
`;

const AboutButton = styled.div`
  cursor: pointer;
  margin-left: auto;
  margin-right: 24px;
`;

const LogoTitle = styled.div`
  cursor: pointer;
  font-size: 24px;
`;

const MyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1172px;
  margin: 0 auto;
`;

const Header = styled.div`
  position: relative;
  z-index: 3;
`;

const Wrapper = styled.div`
  width: 100vw;
  max-width: 1172px;
  margin: 0 auto;
  padding: 0 16px;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100px;
`;
